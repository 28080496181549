<template>
  <v-container class="not-found container-wrapper">
    <v-row>
      <v-col class="text-center pb-1">
        <h1 class="gradient-text-2">404</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center pt-lg-0">
        <div class="gradient-text-2">Oops! We couldn't find a page you were looking for</div>
        <div class="gradient-text-2">Maybe we can help?</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center pt-5 pt-lg-7">
        <v-btn
            rounded
            color="light-green"
            dark
            class="px-8"
            x-large
            depressed
            @click="$router.push('/contact')"
        >
          Contact us
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFoundPage"
}
</script>

<style lang="scss" scoped>
.not-found {
  flex: 1 0 auto;
  padding-top: 80px;
  padding-bottom: 100px;

  h1 {
    font-size: 110px;
    font-weight: bold;
  }
  div {
    font-size: 20px;
    line-height: 1.3;
  }

  @media (min-width: $pad-medium) {
    padding-top: 50px;
    padding-bottom: 50px;

    h1 {
      font-size: 220px
    }
    div {
      font-size: 24px;
      line-height: 1.6;
    }
  }
}
</style>